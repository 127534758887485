import React, {Component,useState  } from 'react'
import { NavLink,useParams  } from 'react-router-dom'
import {baseAPIURL} from "../store/api/Access"

import  { useEffect  } from 'react';
import  axios  from 'axios'
import * as timeago from 'timeago.js';

import { useNavigate  } from 'react-router-dom'
import {AppBar,Box,Checkbox,FormControlLabel,FormGroup} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {Typography,  CardActionArea} from '@mui/material';
import Container from '@mui/material/Container';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TimeAgo from 'react-timeago'


import { connect } from 'react-redux'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Scatter ,Line   } from 'react-chartjs-2';
import {  Chart } from "chart.js";
import {Title, BarElement, CategoryScale } from 'chart.js'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import 'chartjs-adapter-date-fns';

import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';


import Page404 from './Page404';
import Loader from "../components/other/Loader"

//Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, )

ChartJS.register(TimeScale, LinearScale, LineElement, PointElement, Tooltip, Legend);


const View = ({user}) => {
  
  const navigate = useNavigate();
    const [limit, setLimit] = React.useState(30);
    const [details, setDetails] = React.useState(null);
    const [dataList, setDataList] = React.useState(null);
    const [statusDetails, setStatusDetails] = React.useState({loading:true});
    const [statusData, setStatusData] = React.useState({});
  
    let { id } = useParams();


    


      const LoadData = () => {
          setStatusDetails({loading:true});
          //setDetails(null)
          console.log("LoadData")
  
          console.log(firebase.auth().currentUser)
          firebase.auth().currentUser.getIdToken().then(function(idToken) {

            

            let data = JSON.stringify({
                "id": id
              });

            let config = {
              method: 'get',
              url: baseAPIURL+`devices/${id}`,
              headers: { 
                'Authorization': idToken, 
                'Content-Type': 'application/json'
              },
              data : data
            };
            
            axios.request(config).then(Result => {
              console.log(Result)
                if(Result.data.error)
                {
                    setStatusDetails({error:true});

                    return
                }


                setDetails(Result.data)
                setStatusDetails({});

                setStatusData({loading:true})
                let data = JSON.stringify({
                    "id": id
                  });
    
                let config = {
                  method: 'get',
                  url: baseAPIURL+`devices/${id}/data`,
                  headers: { 
                    'Authorization': idToken, 
                    'Content-Type': 'application/json'
                  },
                  data : data
                };
                
                axios.request(config).then(Result => {
                                        
                    console.log(Result)
                    if(Result.data.error)
                    {
                      setStatusData({error:true});
                    }
                    else if(Result.data)
                    {
                        setDataList(Result.data)
                        setStatusData({});
                    }
                    else
                    {
                      console.error("Other Error")
                      console.error(Result)
                      setDataList([])
                        setStatusData({error:true});
                    }
                  
                }).catch(err => {
                      console.error("handleSubmit")
                      console.error(err)
                      setStatusData({error:true});
                });
                
              
          }).catch(err => {
                console.error("handleSubmit")
                console.error(err)
                setStatusDetails({error:true});
          });





        });
          
      };
  
  
  


    
    useEffect(() => {
      LoadData();
    }, []);


    const handleClick = (event, DeviceUid) => {
      console.log(DeviceUid)

      navigate("/devices/" + DeviceUid);
    }




    if(statusDetails.error)
      return(<Page404/>)

    if(statusDetails.loading)
      return(<Loader/>)

    /*
      let chartData = {
        labels: dataList ? dataList.map(item => item.timestamp /*timeago.format(item.timestamp)* /) : [], // Converting the timestamp to a readable format
        datasets: [
          {
            label: 'Max (dB)',
            data: dataList ? dataList.map(item => item.value.maxDb)  : [], // Extract the 'value' from each item
            fill: false,
            backgroundColor: '#207367ff', // For points
            borderColor: '#207367ff', // For line
          },
          {
            label: 'Avg (dB)',
            data: dataList ? dataList.map(item => item.value.averageDb)  : [], // Extract the 'value' from each item
            fill: false,
            backgroundColor: '#207367ff', // For points
            borderColor: '#207367ff', // For line
          },
          {
            label: 'Min (dB)',
            data: dataList ? dataList.map(item => item.value.minDb)  : [], // Extract the 'value' from each item
            fill: false,
            backgroundColor: '#207367ff', // For points
            borderColor: '#207367ff', // For line
          },
        ],
      };


      let options = {
        responsive: true,
        
        scales: {
          x: {
              type: 'date',
          }
        }
      };*/


      let chartData = {
        datasets: [
          {
            label: 'Max (dB)',
            data: dataList
              ? dataList.map((item) => ({
                  x: item.timestamp, // Ensure this is a valid date/time string
                  y: item.value.maxDb,
                }))
              : [],
            fill: false,
            backgroundColor: '#207367ff',
            borderColor: '#207367ff',
          },
          {
            label: 'Avg (dB)',
            data: dataList
              ? dataList.map((item) => ({
                  x: item.timestamp,
                  y: item.value.averageDb,
                }))
              : [],
            fill: false,
            backgroundColor: '#207367ff',
            borderColor: '#207367ff',
          },
          {
            label: 'Min (dB)',
            data: dataList
              ? dataList.map((item) => ({
                  x: item.timestamp,
                  y: item.value.minDb,
                }))
              : [],
            fill: false,
            backgroundColor: '#207367ff',
            borderColor: '#207367ff',
          },
        ],
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute', // Adjust unit as per your data
              displayFormats: {
                minute: 'yyyy-MM-dd HH:mm', // Use valid tokens for date-fns
              },
            },
            title: {
              display: true,
              text: 'Time',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'dB Level',
            },
          },
        },
      };
      
      



    return (
      <main style={{padding:10}}>
            <Card fullWidth sx={{margin: 1 }}>
              <CardContent>

                <h1>Details</h1>

                <p><strong>Name:</strong> {details.device_name}</p>
                <p><strong>Device ID:</strong> {details.device_uuid}</p>
                <p><strong>Location:</strong> {details.location ? details.location : "Unknown"}</p>
                



                <h1>Chart</h1>
                {dataList ? <Line   data={chartData} options={options} /> :null}

                <h1>Data</h1>


                  
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Max (dB)</TableCell>
                        <TableCell>Average (dB)</TableCell>
                        <TableCell>Min (dB)</TableCell>
                        <TableCell align="right">Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      

                    {dataList &&  dataList.length>0 && dataList.map((row, index) => {
                      return (

                      <TableRow 
                        hover 
                        key={row.device_uuid} 
                        /*onClick={(event) => handleClick(event, row.device_uuid)}*/
                      >
                        <TableCell className='ErrorCell'>{row.alerts && <ErrorOutlineIcon/>}</TableCell>
                        <TableCell>{row.value.maxDb} dB</TableCell>
                        <TableCell>{row.value.averageDb} dB</TableCell>
                        <TableCell>{row.value.minDb} dB</TableCell>
                        
                        <TableCell align="right"><TimeAgo date={row.timestamp}/></TableCell>
                      </TableRow>
                      )}
                      )
                    }



                    </TableBody>
                  </Table>
                </TableContainer>



                {statusData.loading ? "Loading" : null}
                {statusData.error ? "Loading" : null}
                
              </CardContent>
            </Card>
          </main>
      );


}








const mapStateToProps = (state) => {
  return {
      user:firebase.auth().currentUser
  }
}
export default connect(mapStateToProps)(View);